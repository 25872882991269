

.container {
    color: white;
    display: flex;
    flex-direction:column; 
    align-items: center;
    width: 100%;
}

.content  {
    width: 100%;
}

.nav {
    display: flex; 
    width: 100%;
    flex-direction: row;
    border: 1px solid gray;
    margin-bottom: 10px;
    text-align: center;
}

.nav div {
    padding: 6px;
    cursor: pointer;
    width: 33.3%;
    
}

.nav div:hover {
    background-color: #3f3f3f;
}