

.link {
    cursor: pointer;
    text-decoration: none; /* no underline */
    color: black;
    width: 100%;
    height: 100%;
    background-color: #efefef;
}

.tutorialContainer:hover {
    cursor: pointer;
    background-color: #efefef;
}