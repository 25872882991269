@import './components/vars';

.container {
    position: relative;
    width: 100%;
    height: $body-height;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
}

.leftContainer {
    display: flex;
    width: 100%;
    height: $canvas-container-height;
}

.rightContainer {
    width: 100%;
}


.audioWaveCanvas {
    position: relative;
    width: 100%;
    height: 100%;
}
