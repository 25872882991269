
@import '../colors';
@import '../vars';


$border-radius: 0.5em;

.container {
    background-color: $color-dark2;
    width: 50%;
    height: $canvas-container-height;

    display: flex;
    align-items: center;
    align-content: center;
}

.canvas {
    border-radius: $border-radius;
    transform-origin: 0 0;
    //transform: scale(0.6);
}
.canvasMount {
    border-radius: $border-radius;
    
    position: relative;
    background-color: black;
    margin-left:auto;
    margin-right: auto;
}

.resize {
    width: 4%;
    height: 4%;
    position: absolute;

    top: 98%;
    left: -2%;
    cursor: ne-resize;

    z-index: 20000;

}