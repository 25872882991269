

@import '../colors';



.title {
    margin-top: 60px;
    font-size: 30px;
}


.resolutionBoxContainer {
    background-color: $color-light1;

    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.button {
    width: 120px;
    height: 80px;
    background-color:$color-light2;
    transition: all .1s ease-in-out;
    cursor: pointer;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5em;
}

.button:hover {
    background-color:$color-light2;
    width: 120px;
    height: 80px;
    border-radius: 0;
    
}

.groupContainer {
    margin-top: 30px;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.itemTitle {
    font-weight: 800;
}

.resolution {
    font-weight: 900;
    bottom: 10px;
}

.items {
    display:flex;
    justify-content: space-between;
}
.groupTitle {
    font-size: 30px;
}

.itemContainer {
    width: 200px;
    display:flex;
    flex-direction: column;
    align-items: center; 
}