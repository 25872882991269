



$color-black: #000000;
$color-dark1: rgb(21, 19, 26);  
$color-dark2: rgb(29, 28, 36);
$color-dark3: rgb(18, 18, 26);

$color-light1: #efefef;
$color-light2: #bcbcbc;
$color-light2: #aaa;
$color-var1: #666;

$color-track-overlay: rgb(0, 106, 155);


