
@import '../colors';
@import '../vars';

.container {
    overflow: hidden;
    width: 100%;
    background-color: $color-dark1;
    
}

