@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(//db.onlinewebfonts.com/c/9cb830e3472ffe12fb4943f3ed80832f?family=Segoe+Print);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: local('Anton Regular'), local('Anton-Regular'), url(https://fonts.gstatic.com/s/anton/v10/1Ptgg87LROyAm3Kz-C8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {font-family: "Segoe Print"; src: url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.eot"); src: url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.woff") format("woff"), url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/9cb830e3472ffe12fb4943f3ed80832f.svg#Segoe Print") format("svg"); }


/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


.simplebar-mask {
  width: 95% !important;
}
.App_container__2j7NA {
  position: relative;
  width: 100%;
  height: 93vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden !important; }

.App_leftContainer__33xZK {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 93%; }

.App_rightContainer__1Oc6G {
  width: 100%; }

.App_audioWaveCanvas__2jSr7 {
  position: relative;
  width: 100%;
  height: 100%; }

.ControllerContainer_container__3U3lr {
  height: 93%;
  width: 50%;
  background-color: #1d1c24;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center; }

.ControllerContainer_bar__3PWxG {
  width: 100%;
  height: 10px;
  background-color: white; }

.ControllerContainer_wrapper__3LR2c {
  box-sizing: content-box;
  margin-top: auto;
  margin-bottom: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-x: hidden;
  height: 70%;
  width: 85%;
  border-radius: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.354);
  border-top: 4px solid rgba(255, 255, 255, 0.315);
  background-color: #1c1a23;
  padding: 22px; }

.ControllerContainer_nav__VH99v {
  width: 60%;
  margin-bottom: 10px; }

.ControllerContainer_nav__VH99v button {
  font-family: 'Montserrat', sans-serif;
  width: 33.3%;
  text-align: center;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 6px;
  font-size: 18px;
  cursor: pointer;
  overflow: hidden; }

.ControllerContainer_navWrapper__3r1Pq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center; }

.ControllerContainer_sideNav__2sR2y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 11pt;
  margin-right: 15px; }

.ControllerContainer_sideNav__2sR2y div {
  cursor: pointer;
  margin-top: 2px;
  width: 120px; }

.ControllerContainer_scrollbar__2_Cqm {
  width: 80%; }

.ControllerContainer_headerButtons__10_c6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  background-color: #15131a;
  width: 100%;
  color: #efefef; }

.ControllerContainer_headerButtons__10_c6 div {
  font-family: 'Montserrat', sans-serif;
  width: 20%;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  padding: 2px; }

.ControllerContainer_headerButtons__10_c6 div:hover {
  background-color: #4345;
  width: 20%; }



.PointComponent_button__3pN1- {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    outline: inherit;
    
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;


}



.PointComponent_button__3pN1-:hover {
    background-color: #efefef;
}
.Automations_container__srfs9 {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center; }

.Automations_column__2ue8T {
  width: 97%;
  margin-left: 5px; }



.ProjectList_root__3COtU {
    width: "100%";
    max-width: 360;
    color: white;
} 

.ProjectList_listItemLight__2gUpQ {
    border-bottom: 1px solid rgba(255,255,255,0.72);
    color: white;
}

.ProjectList_listItemLight__2gUpQ:hover {
    background-color: #efefef;
}

.ProjectList_listItemLight__2gUpQ p {
    color:rgba(255,255,255,0.62);
}


.ProjectList_listItemDark__Njuly {
    border-bottom: 1px solid rgba(0,0,0,0.72);
    color: white;
}

.ProjectList_listItemDark__Njuly:hover {
    background-color: #efefef;
}

.ProjectList_listItemDark__Njuly p {
    color:rgba(0,0,0,0.62);
}


.Project_container__3-QUr {
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:column;
            flex-direction:column; 
    -webkit-align-items: center; 
            align-items: center;
    width: 100%;
}

.Project_content__1idZ9  {
    width: 100%;
}

.Project_nav__Ffbqv {
    display: -webkit-flex;
    display: flex; 
    width: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    border: 1px solid gray;
    margin-bottom: 10px;
    text-align: center;
}

.Project_nav__Ffbqv div {
    padding: 6px;
    cursor: pointer;
    width: 33.3%;
    
}

.Project_nav__Ffbqv div:hover {
    background-color: #3f3f3f;
}
.Header_container__1ZJR6 {
  overflow: hidden;
  width: 100%;
  background-color: #15131a; }

.Canvas_container__1t-Su {
  background-color: #1d1c24;
  width: 50%;
  height: 93%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center; }

.Canvas_canvas__1dMMN {
  border-radius: 0.5em;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; }

.Canvas_canvasMount__PxatZ {
  border-radius: 0.5em;
  position: relative;
  background-color: black;
  margin-left: auto;
  margin-right: auto; }

.Canvas_resize__DW0k0 {
  width: 4%;
  height: 4%;
  position: absolute;
  top: 98%;
  left: -2%;
  cursor: ne-resize;
  z-index: 20000; }

.TrackContainer_container__1iwc1 {
  width: 100%;
  height: 7%;
  background-color: #15131a;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 3px solid #15131a; }

.TrackContainer_trackContainer__1q7vF {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.TrackContainer_controls__2dNEc {
  display: -webkit-flex;
  display: flex;
  margin-right: 2%;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 20%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.TrackContainer_timeDisplay__32rOd {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: #efefef;
  margin-left: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.TrackContainer_seeker__Te2Ne {
  background-color: #efefef;
  width: 60%;
  cursor: pointer;
  border: 2px solid #1d1c24;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto; }

.TrackContainer_seekerOverlay__2EmHB {
  position: absolute;
  height: 26px;
  background-color: #006a9b;
  cursor: pointer; }

.TrackContainer_buttons__1iJxR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%; }

.TrackContainer_volumeContainer__3JyjT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 2%; }

.PlayButton_playButtonOuter__1Amce {
  width: 30px;
  height: 30px;
  cursor: pointer; }

.PlayButton_img__2aYtc {
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.PlayButton_img__2aYtc:hover {
  -webkit-filter: invert(30%);
          filter: invert(30%); }

.Slider_slider__27iy5 {
  -webkit-appearance: none;
  overflow: hidden;
  height: 10px;
  width: 75px;
  cursor: pointer;
  border-radius: 0;
  /* iOS */ }

.Slider_slider__27iy5::-webkit-slider-runnable-track {
  background: #ddd; }

.Slider_slider__27iy5::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0px;
  /* 1 */
  height: 10px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  border: 1px solid #999;
  /* 1 */ }

.ModalContainer_title__2WFF5 {
  margin-top: 60px;
  font-size: 30px; }

.ModalContainer_resolutionBoxContainer__332EV {
  background-color: #efefef;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.ModalContainer_button__I56Lx {
  width: 120px;
  height: 80px;
  background-color: #aaa;
  transition: all .1s ease-in-out;
  cursor: pointer;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 0.5em; }

.ModalContainer_button__I56Lx:hover {
  background-color: #aaa;
  width: 120px;
  height: 80px;
  border-radius: 0; }

.ModalContainer_groupContainer__2CL6J {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.ModalContainer_itemTitle__10CNw {
  font-weight: 800; }

.ModalContainer_resolution__1P4Ow {
  font-weight: 900;
  bottom: 10px; }

.ModalContainer_items__R747l {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.ModalContainer_groupTitle__3EWzE {
  font-size: 30px; }

.ModalContainer_itemContainer__sxLit {
  width: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.AutomationsModal_container__q3NfY {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center; }



.Tutorial_link__3SXrS {
    cursor: pointer;
    text-decoration: none; /* no underline */
    color: black;
    width: 100%;
    height: 100%;
    background-color: #efefef;
}

.Tutorial_tutorialContainer__2TBtA:hover {
    cursor: pointer;
    background-color: #efefef;
}
.AudioTheory_container__2DvlI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

}

.AudioTheory_item__KqpVx {
    margin-top: 15px;
}

.AudioTheory_audioWaveCanvas__dCcyV {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px solid gray;
}

.AudioTheory_grid__k0a-X  div {
    border-top: 1px solid black;
    border-right: 1px solid black;
    padding: 5px;
}

.AudioTheory_grid__k0a-X {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". ." ". ." ". .";
      
}
