$size: 30px;

.playButtonOuter {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.img {
  width: $size;
  height: $size;
  cursor: pointer;
  user-select: none;
}

.img:hover {
  filter: invert(30%);
}
  
