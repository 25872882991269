

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.column {
    width: 97%;
    margin-left: 5px;
}