@import '../colors';
@import '../vars';
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.container {
    height: $canvas-container-height;
    width: 50%;
    background-color: $color-dark2;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;  
}

.bar {
    width :100%;
    height: 10px;
    background-color: white;
}

.wrapper {
    box-sizing: content-box;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    height: 70%;
    width:85%;
    border-radius: 0.5em;
    

    border: 1px solid rgba(255,255,255,0.354);
    border-top: 4px solid rgba(255,255,255,0.315);
    background-color: #1c1a23;
    padding: 22px;
   
}

.nav {
    width: 60%;
    margin-bottom: 10px;
}

.nav button {
    font-family: 'Montserrat', sans-serif;

    width: 33.3%;
    text-align: center;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 6px;
    font-size: 18px;
    cursor: pointer;
    overflow: hidden;
}



.navWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    
}

.sideNav {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 11pt;
    margin-right: 15px;
    

}

.sideNav div {
    cursor: pointer;
    margin-top: 2px;
    width: 120px;
}



.scrollbar  {
    //border-bottom: 1px solid rgba(10,10,10,0.5);
    //border-left: 1px solid rgba(10,10,10,0.5);
    //border-right: 1px solid rgba(10,10,10,0.5);
    width: 80%;
}

.headerButtons {
    display:flex;
    flex-direction: row;
    background-color: $color-dark1;
    width: 100%;
    color: $color-light1;
}

.headerButtons div{
    font-family: 'Montserrat', sans-serif;
    width: 20%;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    padding: 2px;
}

.headerButtons div:hover{
    background-color: #4345;
    width: 20%;
}