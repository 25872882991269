

.button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    outline: inherit;
    
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;


}



.button:hover {
    background-color: #efefef;
}